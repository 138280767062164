@import 'styles/variables.scss';

.planimetry-dialog {
  &-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  &-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .MuiPaper-root {
    height: 90vh;
    width: 90vw;
    padding: 20px;
  }

  .MuiDialogContent-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
