@import 'styles/variables.scss';

.dialog-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 60px 30px;
  font-family: neue-haas-grotesk-display;
  letter-spacing: 0.05em;

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    @media screen and (max-width: $BREAKPOINT_MD) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &-subtitle {
    font-style: italic;
    font-size: 14px;
    line-height: 15px;

    @media screen and (max-width: $BREAKPOINT_MD) {
      font-size: 12px;
      line-height: 10px;
    }
  }

  &-input {
    width: 100%;
    margin: 20px 0;
    padding: 6px 12px;
    text-align: center;
    color: rgba(51, 51, 51, 0.8);
    border: 1px solid #ccc;
    border-radius: 4px;

    & input {
      text-align: center;
    }
  }

  &-confirm-button {
    width: 140px;
    height: 44px;
    background: #f3942d;
    color: #fff;
    font-family: neue-haas-grotesk-display;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    border-radius: 4px;
  }
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
