@import 'styles/variables.scss';

.info-booking {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: $PRIMARY_FONT;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;

  &.white {
    color: #fff;
  }

  &.red {
    color: #f44748;
  }

  &.blue {
    color: #2292e3;
  }

  &.yellow {
    color: #dcbd19;
  }

  img {
    margin-right: 6px;
  }

  span {
    margin-left: 8px;
    font-family: $PRIMARY_FONT;
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;

    @media screen and (max-width: $BREAKPOINT_MD) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 16px;
  }
}
