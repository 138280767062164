@import 'styles/variables.scss';

.keyplan {
  &.position {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 1;

    @media screen and (max-width: $BREAKPOINT_MD) {
      right: 40px;
    }

    @media screen and (min-width: $BREAKPOINT_DESK) {
      bottom: calc(25px + 170px);
    }
  }
  &-container {
    padding: 16px 20px;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(4px);
    border-radius: 8px;

    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -4px;
      right: -4px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      fill: $PRIMARY;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 30%;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        width: 40px;
        height: 40px;
        top: -8px;
        right: -8px;
      }

      @media screen and (min-width: $BREAKPOINT_DESK) {
        bottom: calc(25px + 170px);
      }
    }

    &-image {
      max-width: 200px;
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      transform: scale(0.5);
      transform-origin: 100% 100%;
    }
  }

  &-button {
    padding: 10px 20px;
    font-family: $PRIMARY_FONT;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    background-color: $PRIMARY;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
