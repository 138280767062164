@import "styles/colors";
@import "styles/variables";

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 100%;
  margin: 5px;
  transition: all 400ms ease-in-out;
  font-size: 16px;
  font-weight: 400;
  padding: 20px;

  &:hover {
    background-color: $DEFAULT_COLOR;
    color: #fff;
  }
}

.button-active {
  background-color: $DEFAULT_COLOR;
  color: #fff;
}

.button-arrow {
  border: 2px solid rgb(161, 217, 207);
  background-color: rgb(210, 242, 238);

  &.button-arrow-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.button-arrow-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
