@import 'styles/variables.scss';

.upper-band,
.lower-band {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 516px;
  width: calc(100% - 516px);
  font-family: $PRIMARY_FONT;
  letter-spacing: 0.05em;
}

.upper-band {
  justify-content: space-between;
  top: 0;
  height: 90px;
  padding: 15px 30px;
  background-color: #fff;

  &.less-space {
    padding: 15px;

    .upper-band-commands {
      & .view-wrapper {
        &-separator {
          width: 12px;
          height: 12px;
        }
      }

      &-separator {
        margin: 0 16px;
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $PRIMARY_FONT;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #5b5b5b;
  }

  &-commands {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & .view-wrapper {
      position: relative;
      bottom: 0;
      padding: 0;
      z-index: 0;
    }

    &-separator {
      height: 50px;
      margin: 0 32px; // 54px;
      border-right: 1px solid #333;
    }
  }
}

.lower-band {
  bottom: 0;
  height: 170px;
  padding: 18px 40px;
  background-color: #fff;

  &-filters-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 60px;
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: rgba(131, 121, 121, 0.7);
    border-right: 1px solid #989898;

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      span:first-child {
        font-weight: 700;
      }
    }

    &-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 15px;

      &-names {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 25px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
      }
    }
  }

  &-apartments-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 50px;
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #837979;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;

      &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        span:first-child {
          font-weight: 700;
        }
      }

      &-alert {
        margin-left: 68px;
      }
    }

    &-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        &-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-left: 16px;
          font-family: $PRIMARY_FONT;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: #747474;

          span:last-child {
            font-weight: 500;
            color: #333;
          }
        }
      }
    }
  }
}
