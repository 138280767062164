@import 'styles/variables.scss';

.commands-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 8px 24px;
  background-color: #fff;
  font-family: $PRIMARY_FONT;
  border-bottom: 1px solid $PRIMARY;
  z-index: 3;

  &-back-button {
    font-size: 14px;
    line-height: 21px;
    // letter-spacing: -0.3px;
    color: $PRIMARY;

    span {
      margin-left: 11px;
      font-family: $PRIMARY_FONT;
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &-rotate-button {
    margin-right: 12px;
  }

  @media screen and (max-width: 1024px) {
    &-rotate-button {
      display: none;
    }
  }

  &-logout-button {
    // width: 73px;
    padding: 0 8px;
    height: 29px;
    background-color: #fff;
    border: 1px solid $PRIMARY;
    border-radius: 20px;
    text-align: left;

    span {
      margin-left: 8px;
      text-transform: uppercase;
      font-family: $PRIMARY_FONT;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      color: #333333;
    }
  }
}
