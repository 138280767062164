@import 'styles/fontfaces';

$DEFAULT_FONT: Lato, sans-serif;
$DEFAULT_MARGIN: 30px;
$VERY_SMALL_FONT: 8px;
$SMALL_FONT: 12px;
$SMALL_LABEL_FONT: 14px;
$REGULAR_FONT: 24px;
$BIG_FONT: 28px;
$BIGGER_FONT: 32px;
$DRAWER_WIDTH: 200px;
$BREAKPOINT_SMARTPHONE: 599px;
$BREAKPOINT_MD: 959px;
$BREAKPOINT_DESK: 1800px;
$LOGO_SMALL_WIDTH: 35px;
$LOGO_WIDTH: 50px;
$LOGO_HEIGHT: 50px;
$LOGO_BIG_WIDTH: 65px;
$LOGO_WIDTH_HUGE: 180px;
$LIGHT: 300;
$REGULAR: 400;
$MEDIUM: 500;
$SEMI_BOLD: 600;
$BOLD: 700;
$BLACK: 900;
$DEFAULT_COLOR: #08353b;

$PRIMARY: #f3942d; // sostituisce #48BCA8 e #6bc1b4
$SECONDARY: #fdce9b; // sostituisce #e0efed
$TERTIARY: #94897d;

$PRIMARY_FONT: neue-haas-grotesk-display; // sostituisce Poppins

:export {
  DEFAULT_FONT: $DEFAULT_FONT;
  DEFAULT_MARGIN: $DEFAULT_MARGIN;
  SMALL_FONT: $SMALL_FONT;
  SMALL_LABEL_FONT: $SMALL_LABEL_FONT;
  REGULAR_FONT: $REGULAR_FONT;
  BIG_FONT: $BIG_FONT;
  BIGGER_FONT: $BIGGER_FONT;
  DRAWER_WIDTH: $DRAWER_WIDTH;
  BREAKPOINT_SMARTPHONE: $BREAKPOINT_SMARTPHONE;
  BREAKPOINT_MD: $BREAKPOINT_MD;
  BREAKPOINT_DESK: $BREAKPOINT_DESK;
  LOGO_SMALL_WIDTH: $LOGO_SMALL_WIDTH;
  LOGO_HEIGHT: $LOGO_HEIGHT;
  LOGO_WIDTH: $LOGO_WIDTH;
  LOGO_WIDTH_HUGE: $LOGO_WIDTH_HUGE;
  LOGO_BIG_WIDTH: $LOGO_BIG_WIDTH;
  LIGHT: $LIGHT;
  REGULAR: $REGULAR;
  MEDIUM: $MEDIUM;
  SEMI_BOLD: $SEMI_BOLD;
  BOLD: $BOLD;
  BLACK: $BLACK;
  VERY_SMALL_FONT: $VERY_SMALL_FONT;
  DEFAULT_COLOR: $DEFAULT_COLOR;
  PRIMARY: $PRIMARY;
  SECONDARY: $SECONDARY;
  TERTIARY: $TERTIARY;
  PRIMARY_FONT: $PRIMARY_FONT;
}
