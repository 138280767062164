@import 'styles/variables.scss';

.summary {
  font-family: $PRIMARY_FONT;
  letter-spacing: 0.05em;

  .MuiDrawer-paper {
    margin: 0px;
    width: 100%;
    padding: 0;
  }

  .MuiPaper-root {
    z-index: 1400;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url('../assets/images/sfondo-riepilogo.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(30px) opacity(0.3);
    }
  }

  .promo {
    position: fixed;
    top: 190px;
    right: 0;
    z-index: 1;
    padding-right: 18px;

    @media screen and (max-width: 1024px) {
      top: 60px;
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      top: 55px;
    }
  }

  .fixed-spacer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 46px;
  }

  .content-container {
    width: 100%;
    padding: 16px;

    .title-promo-container {
      display: grid;
      grid-template-columns: 1fr repeat(1, auto) 1fr;
      justify-items: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-height: 80px;
      padding: 11px 16px;
      background-color: #fff5ea;
      color: $PRIMARY;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: 0.05em;
      text-align: center;

      &-ce {
        grid-column-start: 2;
      }

      &-spacing {
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      &-apartment {
        font-weight: 700;
      }

      @media screen and (max-width: 1024px) {
        max-height: 45px;
        font-size: 18px;
        line-height: 27px;
      }
      @media screen and (max-width: $BREAKPOINT_MD) {
        max-height: 80px;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .info-container {
      width: 100%;
      padding: 16px 20px;
      background-color: #f3f3f1;

      .apartment-container {
        width: auto;
        padding-bottom: 16px;

        .apartment {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          background-color: #fff;
          border: 1px solid #adadad;

          &-title {
            width: 100%;
            padding: 8px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background-color: rgba(#f9f6ee, 0.8);
            color: $PRIMARY;

            &-icon {
              width: 12px;
              height: 12px;
              margin: 0 10px 0 30px;

              @media screen and (max-width: 1024px) {
                margin: 0 12px 0 22px;
              }
            }

            &-name {
              margin: auto;
              color: $TERTIARY;
            }

            @media screen and (max-width: 1024px) {
              font-size: 18px;
              line-height: 22px;
            }
            @media screen and (max-width: $BREAKPOINT_MD) {
              display: none;
            }
          }

          &-planimetry-summary {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // max-height: 200px;
            margin: 20px 20px;

            &-icon {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
              z-index: 1;
            }

            &-image {
              width: auto;
              // padding: 23px 30px;
              // transform: rotate(90deg);
              cursor: pointer;
            }

            @media screen and (max-width: 1024px) {
              max-height: 150px;
            }

            @media screen and (max-width: $BREAKPOINT_MD) {
              max-height: 100px;
            }
          }

          &-ranking {
            width: fit-content;
            align-self: center;
            margin: 8px 30px;

            &-ribbon {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 3px;
              font-size: 15px;
              line-height: 22px;
              letter-spacing: 0.05em;
              background-color: rgba($SECONDARY, 0.3);
              color: $PRIMARY;
              border-radius: 16px;

              &-message {
                margin: 0 8px;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.05em;
              }

              // &-top {
              //   margin: 0 8px 0 4px;
              //   font-size: 12px;
              //   line-height: 18px;
              // }

              // &-separator {
              //   @media screen and (max-width: $BREAKPOINT_MD) {
              //     display: none;
              //   }
              // }

              // &-choice {
              //   margin: 0 8px;

              //   @media screen and (max-width: $BREAKPOINT_MD) {
              //     display: none;
              //   }
              // }

              @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 21px;
              }

              @media screen and (max-width: $BREAKPOINT_MD) {
                margin: 0 6px 0 5px;
                font-size: 10px;
                line-height: 15px;
              }
            }
          }

          &-specs {
            display: flex;
            flex-direction: column;
            margin: 16px 30px;
            font-family: $PRIMARY_FONT;
            font-size: 18px;
            font-style: italic;
            line-height: 22px;
            letter-spacing: 0.05em;
            text-align: end;

            &-building,
            &-view,
            &-total-surface,
            &-lodge-surface,
            &-floor {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 5px 0;
              border-top: 1px solid #dadada;

              & img {
                width: 20px;
                height: 20px;

                @media screen and (max-width: 1024px) {
                  width: 18px;
                  height: 18px;
                }

                @media screen and (max-width: $BREAKPOINT_MD) {
                  width: 14px;
                  height: 14px;
                }
              }
            }

            &-floor {
              border-bottom: 1px solid #dadada;

              &-name {
                display: flex;
                flex-direction: row;

                &-change {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                  margin-left: 10px;
                  font-style: italic;
                  color: $PRIMARY;
                  cursor: pointer;

                  &-icon {
                    width: 16px !important;
                    height: 16px !important;
                    margin-left: 8px;
                    cursor: pointer;

                    &.rotate-arrow {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }

            &-info-warnings {
              margin: 23px 0 0;
              font-size: 14px;
              font-style: italic;
              line-height: 17px;
              letter-spacing: 0.05em;
              color: #f44748;
              text-align: start;

              &-proposal {
                display: flex;
                align-items: center;

                &.zero-proposal {
                  display: none;
                }

                & > svg {
                  width: 14px;
                  height: 14px;

                  @media screen and (max-width: 1024px) {
                    width: 12px;
                    height: 12px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    width: 10px;
                    height: 10px;
                  }
                }
                & span {
                  margin-left: 14px;

                  @media screen and (max-width: 1024px) {
                    margin-left: 5px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    margin-left: 4px;
                  }
                }
              }

              @media screen and (max-width: 1024px) {
                font-size: 12px;
                line-height: 17px;
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                font-size: 10px;
                line-height: 11px;
              }
            }

            @media screen and (max-width: 1024px) {
              margin: 18px;
              font-size: 16px;
              line-height: 20px;
            }
            @media screen and (max-width: $BREAKPOINT_MD) {
              margin: 16px;
              font-size: 12px;
              line-height: 14px;
            }

            @media screen and (max-width: 1024px) {
              margin: 16px 18px;
              font-size: 16px;
              line-height: 19px;
            }
            @media screen and (max-width: $BREAKPOINT_MD) {
              margin: 16px 16px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .apartment-summary-container {
          padding: 16px 23px;
          background-color: #fff;
          border-left: 18px solid #f3f3f1;

          &-price-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 24px;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.05em;

            &-apartment-name {
              color: #adadad;

              & .name {
                text-transform: lowercase;
              }

              @media screen and (max-width: 1024px) {
                font-size: 12px;
                line-height: 18px;
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                display: none;
              }
            }

            &-container {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 16px;
              background-color: rgba(196, 196, 196, 0.15);

              &-value {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &-cost {
                  position: relative;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 30px;
                  letter-spacing: 0.05em;

                  &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    border-top: 2px solid $PRIMARY;
                    transform: matrix(0.99, -0.13, 0.13, 0.99, 0, 0);
                  }

                  @media screen and (max-width: 1024px) {
                    font-size: 18px;
                    line-height: 27px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    font-size: 14px;
                    line-height: 14px;
                  }
                }
                @media screen and (max-width: 1024px) {
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  font-size: 14px;
                  line-height: 28px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  width: 100%;
                  font-size: 10px;
                  line-height: 14px;
                }
              }

              &-separator {
                display: none;

                @media screen and (max-width: 1024px) {
                  display: block;
                  width: 100%;
                  height: 8px;
                }
              }

              &-promo {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                padding: 5px 15px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                background-color: #fff;
                border-radius: 3px;

                &-value {
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 30px;
                  letter-spacing: 0.05em;

                  @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 21px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    font-size: 10px;
                    line-height: 15px;
                  }
                }

                @media screen and (max-width: 1024px) {
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  font-size: 18px;
                  line-height: 27px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  width: 100%;
                  font-size: 14px;
                  line-height: 21px;
                }
              }

              &-promo-de {
                display: flex;
                direction: row;
                align-items: center;
                margin-top: 8px;
                font-style: italic;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;
                color: #f44748;

                img {
                  margin-right: 12px;

                  @media screen and (max-width: 1024px) {
                    margin-right: 10px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    margin-right: 5px;
                  }
                }

                @media screen and (max-width: 1024px) {
                  font-size: 12px;
                  line-height: 17px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  font-size: 10px;
                  line-height: 11px;
                }
              }
            }

            &-value {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 20px;

              span:first-child {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                text-transform: uppercase;

                @media screen and (max-width: 1024px) {
                  font-size: 14px;
                  line-height: 21px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  font-size: 10px;
                  line-height: 15px;
                }
              }
              span:last-child {
                font-weight: 700;
                font-size: 50px;
                line-height: 75px;
                letter-spacing: 0.05em;
                color: $PRIMARY;

                @media screen and (max-width: 1024px) {
                  font-size: 40px;
                  line-height: 60px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  font-size: 30px;
                  line-height: 45px;
                }
              }
            }

            &-cantina {
              text-align: center;
              font-family: $PRIMARY_FONT;
              margin-bottom: 15px;
            }

            &-installment {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-top: 8px;
              padding: 8px 0;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: rgba(51, 51, 51, 0.6);
              border-top: 1px solid rgba(51, 51, 51, 0.6);
              border-bottom: 1px solid rgba(51, 51, 51, 0.6);

              strong {
                font-size: 18px;
                line-height: 27px;

                @media screen and (max-width: 1024px) {
                  font-size: 20px;
                  line-height: 30px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  font-size: 14px;
                  line-height: 21px;
                }
              }

              @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 21px;
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                font-size: 12px;
                line-height: 18px;
              }
            }

            &-promo {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-top: 40px;
              background-color: #faf8f1;
              border-radius: 2px;

              img {
                padding: 40px 33px;

                @media screen and (max-width: 1024px) {
                  padding: 35px 23px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  // padding: 25px 14px;
                  display: none;
                }
              }

              &-description {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                padding: 10px 10px 10px 0;
                font-family: $PRIMARY_FONT;
                font-style: italic;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.05em;
                color: #333333;

                &-title {
                  width: 100%;
                  margin-bottom: 10px;
                  padding-bottom: 5px;
                  font-family: $PRIMARY_FONT;
                  font-weight: 600;
                  font-style: normal;
                  font-size: 15px;
                  line-height: 22px;
                  letter-spacing: 0.05em;
                  color: $PRIMARY;
                  border-bottom: 1px solid rgba(#fbe5ce, 0.5);

                  @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 21px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    font-size: 12px;
                    line-height: 18px;
                  }
                }

                &-price {
                  padding: 4px 0;
                  font-weight: 700;
                  font-size: 45px;
                  line-height: 53px;
                  letter-spacing: 0.05em;

                  @media screen and (max-width: 1024px) {
                    font-size: 32px;
                    line-height: 36px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    font-size: 24px;
                    line-height: 32px;
                  }
                }

                @media screen and (max-width: 1024px) {
                  font-size: 14px;
                  line-height: 16px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  align-items: center;
                  padding: 10px;
                  font-size: 10px;
                  line-height: 11px;
                }
              }
            }
          }

          &-appointment {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border: 2px solid $PRIMARY;

            &-skip-queue {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 30px 34px 34px 37px;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: #000;
              border-bottom: 1px solid #dadada;

              &-title {
                img {
                  margin-right: 18px;

                  @media screen and (max-width: 1024px) {
                    margin-right: 13px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    display: none;
                  }
                }
                span {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  letter-spacing: 0.05em;
                  text-transform: uppercase;
                  color: $TERTIARY;

                  @media screen and (max-width: 1024px) {
                    font-size: 18px;
                    line-height: 27px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    font-size: 14px;
                    line-height: 21px;
                  }
                }

                &-spacing {
                  margin-left: 34px;
                  font-size: 18px;

                  @media screen and (max-width: 1024px) {
                    margin-left: 29px;
                    font-size: 16px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    margin-left: 0;
                    font-size: 14px;
                  }
                }
              }

              @media screen and (max-width: 1024px) {
                padding: 20px 20px 20px 26px;
                font-size: 14px;
                line-height: 18px;
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                padding: 12px;
                font-size: 12px;
                line-height: 15px;
              }
            }

            &-booking {
              padding: 36px 27px 43px 27px;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.05em;
              color: #87898d;

              &-buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;

                &-videocall,
                &-showroom,
                &-cart,
                &-wishlist {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  padding: 8px 12px;
                  font-family: $PRIMARY_FONT;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.05em;
                  background-color: $PRIMARY;
                  color: #fff;
                  border: 1px solid $PRIMARY;
                  border-radius: 2px;

                  img {
                    width: 30px;
                    height: 30px;
                    margin-right: 12px;
                  }

                  @media screen and (max-width: 1024px) {
                    padding: 8px;
                  }
                  @media screen and (max-width: $BREAKPOINT_MD) {
                    padding: 6px;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }

                &-cart:disabled,
                &-wishlist:disabled {
                  background-color: rgba(0, 0, 0, 0.12);
                  color: rgba(0, 0, 0, 0.26);
                  border: 1px solid rgba(0, 0, 0, 0.26);
                }

                &-separator {
                  width: 16px;

                  @media screen and (max-width: 1024px) {
                    height: 8px;
                  }
                }

                @media screen and (max-width: 1024px) {
                  flex-direction: column;
                  justify-content: center;
                }
              }

              &-myai-back {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 50%;
                margin: 16px auto 0;
                padding: 8px 12px;
                font-family: neue-haas-grotesk-display;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.05em;
                background-color: $PRIMARY;
                color: #fff;
                border: 1px solid #f3942d;
                border-radius: 2px;

                @media screen and (max-width: 1024px) {
                  width: 100%;
                }
              }

              @media screen and (max-width: 1024px) {
                padding: 20px 20px 22px 20px;
                font-size: 14px;
                line-height: 18px;
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                padding: 12px;
                font-size: 10px;
                line-height: 16px;
              }
            }

            &-pdf {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              &-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                padding: 18px;
                border-bottom: 1px solid #dadada;

                & img {
                  margin-right: 18px;

                  @media screen and (max-width: $BREAKPOINT_MD) {
                    display: none;
                  }
                }
              }

              &-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 60%;
                margin: 24px;
                padding: 10px 16px;
                font-family: $PRIMARY_FONT;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.05em;
                background-color: $PRIMARY;
                color: #fff;
                border: 1px solid $PRIMARY;
                border-radius: 2px;

                @media screen and (max-width: 1024px) {
                  padding: 8px;
                }

                @media screen and (max-width: $BREAKPOINT_MD) {
                  padding: 6px;
                  font-size: 12px;
                  line-height: 18px;
                  justify-content: center;
                }

                img {
                  width: 30px;
                  height: 30px;
                  margin-right: 12px;

                  @media screen and (max-width: $BREAKPOINT_MD) {
                    display: none;
                  }
                }
              }
            }

            &-image {
              width: 100%;
              height: fill-available;
              background-image: url('../assets/images/sfondo-promo.jpg');
              background-position: center center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }

          @media screen and (max-width: $BREAKPOINT_MD) {
            padding: 16px;
          }
        }
      }

      .notes {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: $PRIMARY_FONT;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-align: justify;
        color: rgba(51, 51, 51, 0.7);

        @media screen and (max-width: 1024px) {
          font-size: 11px;
          line-height: 13px;
        }
        @media screen and (max-width: $BREAKPOINT_MD) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      height: fill-available;
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      height: auto;
    }
  }

  .simple-rotate-button {
    position: fixed;
    bottom: 24px;
    left: 16px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.change-floor-popper {
  margin: 0;
  font-family: $PRIMARY_FONT;
  z-index: 1300;

  .popper-view {
    width: 125px;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 2px;

    .popper-list {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      padding: 0;
      margin: 0;
      width: 100%;
      // margin-top: 20px;

      li {
        list-style-type: none;

        &:first-of-type {
          > button {
            // border-top: 1px solid #ccc;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }

        &:last-of-type > button {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        button {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          width: 100%;
          max-height: 39px;
          padding: 8px;
          background-color: white;
          font-family: $PRIMARY_FONT;
          font-size: 14px;
          letter-spacing: 0.05em;
          text-align: left;
          border: 0;
          //   border-bottom: 1px solid #ccc;
          //   border-left: 1px solid #ccc;
          //   border-right: 1px solid #ccc;

          &:hover,
          // &:focus,
          &:active {
            cursor: pointer;
            // font-weight: bold;
            background-color: lighten(#caccce, 5%);
          }

          &.selected {
            font-weight: 700;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        max-height: 65vh;
        overflow-y: auto;
      }
      @media screen and (max-width: $BREAKPOINT_MD) {
        max-height: 62vh;
        overflow-y: auto;
      }
    }
  }
}

.cart-wish-snackbar {
  .MuiPaper-root {
    justify-content: center;
    padding: 0 8px;
    background: #4cb050;
    color: #fff;
  }
}
