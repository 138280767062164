@import 'styles/variables.scss';

.dialog-promo {
  font-family: $PRIMARY_FONT;
  // .MuiPaper-root {
  //   padding: 0;
  // }

  // .MuiDialogContent-root {
  //   padding: 0;

  //   &:first-child {
  //     padding-top: 0;
  //   }
  // }

  .dialog-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &-showroom {
      background-image: url('../assets/images/sfondo-modale-appuntamento-showroom.jpg');
    }
    &-videocall {
      background-image: url('../assets/images/sfondo-modale-appuntamento-videocall.jpg');
    }

    &-logo {
      width: 200px;
      margin-top: 40px;
    }
  }
  .dialog-content {
    margin: 40px;
    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-bottom: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #333;
      text-align: center;

      &-hightlight {
        color: $PRIMARY;
      }
    }
    &-description {
      padding: 50px 0;
      border-top: 1px solid rgba($PRIMARY, 0.5);
      border-bottom: 1px solid rgba($PRIMARY, 0.5);

      &-message {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-of-type {
          margin-bottom: 40px;
        }

        &-icon {
          width: 40px;
          height: 40px;
          margin-right: 24px;
        }
        &-me {
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 8px;
          }

          span:first-child {
            text-transform: uppercase;
          }

          & .highlight {
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          }
        }
      }
    }
    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      &-description {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: #333333;
      }
      &-button {
        width: 60%;
        font-family: $PRIMARY_FONT;
        font-weight: 500;
        font-size: 15px;
        // line-height: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        background-color: $PRIMARY;
        color: #fff;
        border-radius: 4px;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  @media screen and (max-width: $BREAKPOINT_MD) {
    display: none;
  }
}

.dialog-promo-xs {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0;
    padding: 0 18px;
    border-right: 1px solid $PRIMARY;

    &-description {
      &-message {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-of-type {
          margin-bottom: 30px;
        }

        &-icon {
          width: 40px;
          height: 40px;
          margin-right: 24px;
        }
        &-me {
          display: flex;
          flex-direction: column;
          font-family: $PRIMARY_FONT;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #000;

          span {
            margin-bottom: 8px;
          }

          span:first-child {
            text-transform: uppercase;
          }

          &-2 {
            font-family: $PRIMARY_FONT;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.05em;
            color: #000;
            text-transform: none;

            & .highlight {
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .dialog-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #333;
    text-align: center;

    &-hightlight {
      color: $PRIMARY;
    }
    &-logo {
      width: 150px;
    }

    &-content {
      font-family: $PRIMARY_FONT;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #333;

      &-hightlight {
        color: $PRIMARY;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      &-description {
        margin-bottom: 15px;
        font-family: $PRIMARY_FONT;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #333;
        text-transform: none;
      }
      &-button {
        width: 100%;
        font-family: $PRIMARY_FONT;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.05em;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        background-color: $PRIMARY;
        color: #fff;
        border-radius: 4px;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
  }

  @media screen and (min-width: $BREAKPOINT_MD) {
    display: none;
  }
}

.MuiPaper-root {
  padding: 0;
}

.MuiDialogContent-root {
  padding: 0;

  &:first-child {
    padding-top: 0;
  }
}

.MuiDialog-paper {
  @media screen and (max-width: $BREAKPOINT_MD) {
    margin: 16px;
  }
}

.MuiDialog-paperScrollPaper {
  @media screen and (max-width: $BREAKPOINT_MD) {
    max-height: calc(100% - 32px);
  }
}
