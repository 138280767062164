@import 'styles/colors';
@import 'styles/variables';
@import 'styles/fontfaces';

html {
  padding: env(safe-area-inset);
}

body {
  font-family: $DEFAULT_FONT;
  font-weight: $LIGHT;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: $BACKGROUND_PAGE;
  overflow: hidden;
}

.default-font {
  font-family: $DEFAULT_FONT;
}

.main-container {
  //   width: calc(100% - #{$DRAWER_WIDTH});
  //   margin-right: 0px;
  //   margin-left: 200px;
  //   @media (max-width: $BREAKPOINT_MD) {
  //     width: 100%;
  //     margin-left: 0px;
  //   }
}

.body-wrapper {
  //   margin: 10px $DEFAULT_MARGIN 10px $DEFAULT_MARGIN;
}

.small-font-6 {
  font-size: 6px;
}

.small-font-8 {
  font-size: 8px;
}

.small-font-9 {
  font-size: 9px;
}

.small-font-10 {
  font-size: 10px;
}

.small-font-11 {
  font-size: 11px;
}

.small-font-12 {
  font-size: 12px;
}

.elements-spacing {
  margin: 20px 5px;
}

.MT-0 {
  margin-top: 0px;
}

.MT-10 {
  margin-top: 10px;
}

.MR-10 {
  margin-right: 10px;
}

.ML-10 {
  margin-left: 10px;
}

.MR-30 {
  @media (min-width: $BREAKPOINT_MD) {
    margin-right: 30px;
  }
}

.width-100 {
  width: 100%;
}

.full-width {
  @extend .width-100;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 15px;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-column-justified {
  @extend .flex-column;
  justify-content: space-between;
}

.flex-centered {
  @extend .flex;
  align-items: center;
}

.flex-centered-between {
  @extend .flex-centered;
  justify-content: space-between;
}

.flex-centered-between-wrapped {
  @extend .flex-centered-between;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.flex-wrap-container {
  @extend .flex;
  flex-wrap: wrap;
}

.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}

.flex-row-3 {
  flex-basis: 30%;
}

.input-text {
  margin: 10px 5px 20px 5px !important;
  background-color: white;
}

.image-size {
  object-fit: scale-down;
  object-position: center center;
  max-width: 100%;
  max-height: 400px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  @extend .default-font;
  font-weight: $LIGHT !important;
}

.regular {
  @extend .default-font;
  font-weight: $REGULAR !important;
}

.medium {
  @extend .default-font;
  font-weight: $MEDIUM !important;
}

.semi-bold {
  @extend .default-font;
  font-weight: $SEMI_BOLD !important;
}

.bold {
  @extend .default-font;
  font-weight: $BOLD !important;
}

.black {
  @extend .default-font;
  font-weight: $BLACK !important;
}

.unselected {
  color: $DISABLED !important;
}

// .selected {
//   color: $DEFAULT_TEXT !important;
// }

.backdrop-color {
  background-color: #000;
}
.MuiCircularProgress-colorPrimary {
  color: #fff !important;
}

.backdrop-transparent {
  color: $BACKGROUNDS_TRANSPARENT;
}

.default-background {
  background-color: $BACKGROUNDS;
}

.more-z {
  z-index: 101 !important;
}

.table-container {
  padding: 0 !important;
}

.color-white {
  color: white !important;
  fill: white !important;
}

.libero {
  @extend .color-white;
  background-color: $LIBERO !important;
}

.interesse {
  @extend .color-white;
  background-color: $INTERESSE !important;
}

.proposta {
  @extend .color-white;
  background-color: $PROPOSTA !important;
}

.compromesso {
  @extend .color-white;
  background-color: $COMPROMESSO !important;
}

.rogitato {
  @extend .color-white;
  background-color: $ROGITATO !important;
}

.opzionato {
  @extend .color-white;
  background-color: $OPZIONATO !important;
}

.richiesto {
  @extend .color-white;
  background-color: $RICHIESTO !important;
}
.cancellato {
  @extend .color-white;
  background-color: $CANCELLATO !important;
}
.riservato {
  @extend .color-white;
  background-color: $RISERVATO !important;
}
.search-button {
  @extend .color-white;
  background-color: $SEARCH_BUTTON !important;
  padding: 10px !important;
}

.modify-button {
  @extend .search-button;
}

.add-button {
  @extend .search-button;
}

.remove-button {
  @extend .color-white;
  padding: 10px !important;
  background-color: $EDITING_COLOR !important;
}
.border-point {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-right: 5px;
}
.icons-in-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px !important;
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.card-wrapper.user-background,
.user-background {
  background-color: $USER_BACKGROUND !important;
}
.event-store-background {
  background-color: $EVENT_STORE_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.event-video-background {
  background-color: $EVENT_VIDEO_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.event-call-background {
  background-color: $EVENT_CALL_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-width {
  width: 95% !important;
  margin: 5px;
}
.smaller-font {
  font-size: $SMALL_LABEL_FONT;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal-custom-container {
  padding: 5px 10px;
  margin: 20px 15px 20px 0px;
  border-radius: 30px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
}

.add-appartments-modal-content {
  min-height: 200px;
  min-width: 400px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: unset;
  }
}

.custom-listbox {
  background-color: $SELECT_BACKGROUND;
  color: $USER_DATA_COLOR;
}

.list-divider {
  border-bottom: 1px solid $USER_DATA_COLOR;
}

.loader-wrapper {
  min-height: 60px;
}

.absolute-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.absolute-position-relative-content-loader {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.relative {
  position: relative;
}

.snackbar-body {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $COLOR_PRIMARY;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.snackbar-body-error {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $ERROR_COLOR;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.magnified-container {
  background-color: white;
  cursor: zoom-in !important;
}

.zoom-options {
  align-self: normal;
  margin-right: 5px;
}

.image-zoom-container {
  width: fit-content;
}

.zoom-button {
  background: $COLOR_PRIMARY;
  color: $COLOR_SECONDARY;
}

.ellipsis {
  text-overflow: ellipsis;
}

.justify-center {
  justify-content: center;
}

.link-on-hover {
  transition: 0.5s;
  &:hover {
    color: $EDITING_COLOR !important;
  }
}

.privacy-divider {
  margin-top: 20px;
}

.additional-divider {
  margin-top: 20px;
  margin-bottom: 10px;
}

.button-right {
  margin-left: 95%;
}

.expandClient {
}
.MuiFormHelperText-root.Mui-error {
  margin-top: 20px;
}
.shrink-down {
  transform: translate(0px, 100px) scale(0.75) !important;
}

.custom-circular-progress {
  width: 80px !important;
  height: 80px !important;

  svg circle {
    stroke: $PRIMARY;
    stroke-width: 2;
  }

  @media screen and (max-width: $BREAKPOINT_MD) {
    width: 60px !important;
    height: 60px !important;
  }
}
