@import 'styles/variables.scss';

@mixin styling() {
  background-color: #caccce;
  // border: 1px solid #ccc;
  border: 1px solid #f9f6ee;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

$dropdown-height: 38px;

/* DROPDOWN
  ============================================ */

.dd-wrapper {
  display: flex;
  min-height: $dropdown-height;
  flex-wrap: wrap;
  position: relative;
  font-family: $PRIMARY_FONT;

  .dd-header {
    @include styling();
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0 20px;
    cursor: pointer;

    &__title {
      width: 100%;
      padding: 0 12px;

      &--bold {
        margin: 0;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        &.placeholder {
          color: lighten(#1e201f, 40%);
        }

        @media screen and (max-width: $BREAKPOINT_MD) {
          width: 120px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.05em;
        }
      }
    }

    &__action {
      display: flex;
      width: 32px;
      height: 100%;
      padding: 0 12px;
      background: $SECONDARY;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #f9f6ee;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &.open {
        border-bottom-right-radius: 0;
      }

      p {
        margin: 0;
        padding-top: 5px;
      }
    }

    &.open {
      border-color: $PRIMARY;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.selected {
      // border-color: $PRIMARY;
      background-color: #fff;
      box-shadow: 0 0.125rem 0.25rem rgba($PRIMARY, 0.5);
    }
  }

  .dd-list {
    @include styling();
    position: absolute;
    top: $dropdown-height;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 1px solid $PRIMARY;
    // border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 0.2rem 0.25rem rgba($PRIMARY, 0.5);
    z-index: 2;

    li {
      list-style-type: none;

      //   &:first-of-type {
      //     > button {
      //       border-top: 1px solid #ccc;
      //       border-top-left-radius: 4px;
      //       border-top-right-radius: 4px;
      //     }
      //   }

      &:last-of-type > button {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #caccce;
        font-family: $PRIMARY_FONT;
        font-size: 14px;
        letter-spacing: 0.05em;
        padding: 10px 12px;
        border: 0;
        // border-bottom: 1px solid #ccc;
        width: 100%;
        text-align: left;

        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }

        &:hover,
        &:focus {
          cursor: pointer;
          // font-weight: bold;
          background-color: lighten(#caccce, 5%);
        }

        .subtitle {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.75);

          @media screen and (max-width: 1024px) {
            font-size: 8px;
          }
        }
      }
    }
  }
}
