@import 'styles/variables.scss';

#starting-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/images/sfondo-generale.png') rgba(0, 0, 0, 0.6);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  z-index: 1301;

  img {
    width: 250px;
    height: 100px;
    margin-top: 40px;

    @media screen and (max-width: $BREAKPOINT_MD) {
      width: 150px;
      height: 60px;
      margin-top: 32px;
    }
  }

  &-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 44px;
    padding: 0 16px;
    background-color: rgba($PRIMARY, 0.9);
    color: #fff;
    font-family: $PRIMARY_FONT;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;

    @media screen and (max-width: 1024px) {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      margin: auto;
    }
  }
}

#oops-screen-container {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/images/sfondo-generale.png') rgba(0, 0, 0, 0.6);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  z-index: 1204;

  img {
    width: 250px;
    height: 100px;
    margin-top: 40px;

    @media screen and (max-width: $BREAKPOINT_MD) {
      width: 150px;
      height: 60px;
      margin-top: 32px;
    }
  }

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 300px;
      height: 150px;

      @media screen and (max-width: $BREAKPOINT_MD) {
        width: 168px;
        height: 62px;
      }
    }

    p {
      margin-bottom: 41px;
      font-family: $PRIMARY_FONT;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #fff;

      @media screen and (max-width: $BREAKPOINT_MD) {
        margin-bottom: 28px;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      & button {
        background-color: rgba($PRIMARY, 0.8);
        color: #fff;
        border-radius: 4px;
        font-family: $PRIMARY_FONT;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
      }
    }

    @media screen and (max-width: 1024px) {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      margin: auto;
    }
  }
}

#waiting-screen-container {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/images/sfondo-generale.png') rgba(0, 0, 0, 0.6);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  z-index: 1301;

  img {
    width: 250px;
    height: 100px;
    margin-top: 40px;

    @media screen and (max-width: $BREAKPOINT_MD) {
      width: 150px;
      height: 60px;
      margin-top: 32px;
    }
  }

  &-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &-circular {
      width: 80px !important;
      height: 80px !important;

      svg circle {
        stroke: $PRIMARY;
        stroke-width: 2;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        width: 60px !important;
        height: 60px !important;
      }
    }

    &-description {
      font-family: $PRIMARY_FONT;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #fff;

      @media screen and (max-width: $BREAKPOINT_MD) {
        font-size: 16px;
        line-height: 21px;
      }
    }

    &-reconnection-description {
      font-family: $PRIMARY_FONT;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #fff;
      display: none;

      @media screen and (max-width: $BREAKPOINT_MD) {
        font-size: 16px;
        line-height: 21px;
      }
    }

    @media screen and (max-width: 1024px) {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      margin: auto;
    }
  }
}
