@import 'styles/button';
@import 'styles/variables.scss';

.selection-box {
  position: relative;
  padding: 16px 18px 16px 30px;
  border-bottom: 1px solid #f9f6ee;

  @media screen and (max-width: 1024px) {
    padding: 15px 15px 15px 21px;
  }

  @media screen and (max-width: $BREAKPOINT_MD) {
    padding: 10px 15px 8px 18px;
  }

  &.disabled {
    pointer-events: none;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-filter-number {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      background-color: $SECONDARY;
      color: #333;
      border: 2px solid #f9f6ee;
      border-radius: 50%;

      @media screen and (max-width: $BREAKPOINT_MD) {
        width: 22px;
        height: 22px;
      }
    }

    .filter-header {
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #fff;

      &.info {
        font-weight: 700;
        font-style: italic;
        line-height: 16px;
        text-decoration: underline;
        cursor: pointer;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        font-size: 12px;
        line-height: 14px;
      }
    }

    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      margin-bottom: 8px;
    }
  }
}

.popper {
  z-index: 1201;

  @media screen and (max-width: 1024px) {
    max-width: 254px;
  }
  @media screen and (max-width: $BREAKPOINT_MD) {
    max-width: 213px;
    margin: 16px;
  }
  .popper-info {
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-container {
        font-size: 14px;
        color: #1e201f;
        &-title {
          font-size: 16px;
          line-height: 21px;

          @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        &-subtitle {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          color: $PRIMARY;

          @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
        p {
          margin: 0;
        }
      }

      &-close {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border: 1px solid #1e201f;
        border-radius: 50%;
        cursor: pointer;

        & > svg {
          font-size: 1rem;
        }
      }
    }
    &-body {
      &-price {
        font-size: 15px;
        line-height: 20px;

        @media screen and (max-width: 1024px) {
          font-size: 12px;
          line-height: 16px;
        }
        &-highlight {
          font-weight: 700;

          @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      &-description {
        font-size: 14px;
        line-height: 18px;

        @media screen and (max-width: 1024px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-top: 2px solid rgb(161, 217, 207);
  border-bottom: 2px solid rgb(161, 217, 207);
  background-color: rgb(202, 205, 206);
  text-transform: uppercase;
}
