@import 'styles/variables.scss';

$fab-dimension: 44px;

$drawerWidth: 400px;
$drawerWidthMD: 300px;
$drawerWidthSM: 250px;

.view-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 0 12px 16px;
  font-family: $PRIMARY_FONT;
  letter-spacing: 0.05em;
  background-color: #fff;
  color: $PRIMARY;
  border-radius: 4px;
  transition: left 180ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1200;

  &-title {
    margin-bottom: 16px;
    font-family: $PRIMARY_FONT;
    font-style: italic;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: $PRIMARY;

    @media screen and (max-width: $BREAKPOINT_MD) {
      display: none;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-icon {
      width: 34px;
      height: 37px;
    }

    &-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 250px;
      height: 38px;
      margin-left: 6px;
      padding: 3px 12px 3px 12px;
      background: linear-gradient(
        116.95deg,
        rgba(255, 255, 255, 0.46) 26.65%,
        rgba(255, 255, 255, 0.6) 81.98%
      );
      border-radius: 4px;
      cursor: pointer;

      .rotate-arrow {
        transform: rotate(180deg);
      }

      span {
        font-style: italic;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: #333;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        width: 144px;
      }
    }
  }
}

.popper-container {
  margin: 0;
  font-family: $PRIMARY_FONT;
  z-index: 1202;

  &.shiftFabRight {
    transition: left 180ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .popper-view {
    width: 306px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;

    &.rotate-180 {
      transform: rotate(180deg);
    }

    .popper-list {
      width: 100%;
      // margin-top: 20px;
      padding: 0;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);

      &-separator {
        padding: 14px 12px;
        font-family: $PRIMARY_FONT;
        font-style: italic;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.05em;
        background-color: #fff;
        color: $PRIMARY;
        border-top: 1px solid $PRIMARY;
      }

      li {
        list-style-type: none;

        &:first-of-type {
          > button {
            // border-top: 1px solid #ccc;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }

        &:last-of-type > button {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-height: 39px;
          padding: 22px 20px;
          font-family: $PRIMARY_FONT;
          background-color: white;
          font-size: 14px;
          letter-spacing: 0.05em;
          text-align: left;
          border: 0;

          &:hover,
          &:active {
            cursor: pointer;
            background-color: lighten(#caccce, 5%);
          }

          &.selected {
            font-weight: 700;
            background-color: $PRIMARY;
            color: #fff;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        max-height: 65vh;
        overflow-y: auto;
      }
      @media screen and (max-width: $BREAKPOINT_MD) {
        max-height: 52vh;
        overflow-y: auto;
      }
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      width: 184px;
    }
  }
}
