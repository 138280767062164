@font-face {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  src: url('fonts/Segoe/SegoeUI.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/8d734b/00000000000000003b9b2040/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/8d734b/00000000000000003b9b2040/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3)
      format('woff'),
    url(https://use.typekit.net/af/8d734b/00000000000000003b9b2040/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3)
      format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/385686/00000000000000003b9b2041/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/385686/00000000000000003b9b2041/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3)
      format('woff'),
    url(https://use.typekit.net/af/385686/00000000000000003b9b2041/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3)
      format('opentype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/4d5fe8/00000000000000003b9b2042/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/4d5fe8/00000000000000003b9b2042/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3)
      format('woff'),
    url(https://use.typekit.net/af/4d5fe8/00000000000000003b9b2042/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3)
      format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/72a1b1/00000000000000003b9b2043/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/72a1b1/00000000000000003b9b2043/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3)
      format('woff'),
    url(https://use.typekit.net/af/72a1b1/00000000000000003b9b2043/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3)
      format('opentype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3)
      format('woff'),
    url(https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3)
      format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/2d3659/00000000000000003b9b2045/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/2d3659/00000000000000003b9b2045/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3)
      format('woff'),
    url(https://use.typekit.net/af/2d3659/00000000000000003b9b2045/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3)
      format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3)
      format('woff'),
    url(https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3)
      format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/032ccd/00000000000000003b9b2047/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/032ccd/00000000000000003b9b2047/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3)
      format('woff'),
    url(https://use.typekit.net/af/032ccd/00000000000000003b9b2047/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3)
      format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3)
      format('woff'),
    url(https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3)
      format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/7391a1/00000000000000003b9b2049/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/7391a1/00000000000000003b9b2049/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3)
      format('woff'),
    url(https://use.typekit.net/af/7391a1/00000000000000003b9b2049/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3)
      format('opentype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3)
      format('woff'),
    url(https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3)
      format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/a659fe/00000000000000003b9b204b/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/a659fe/00000000000000003b9b204b/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3)
      format('woff'),
    url(https://use.typekit.net/af/a659fe/00000000000000003b9b204b/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3)
      format('opentype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/d562ce/00000000000000003b9b204c/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/d562ce/00000000000000003b9b204c/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3)
      format('woff'),
    url(https://use.typekit.net/af/d562ce/00000000000000003b9b204c/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3)
      format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/661dc5/00000000000000003b9b204d/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/661dc5/00000000000000003b9b204d/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3)
      format('woff'),
    url(https://use.typekit.net/af/661dc5/00000000000000003b9b204d/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3)
      format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/375e66/00000000000000003b9b204e/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/375e66/00000000000000003b9b204e/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3)
      format('woff'),
    url(https://use.typekit.net/af/375e66/00000000000000003b9b204e/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3)
      format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: neue-haas-grotesk-display;
  src: url(https://use.typekit.net/af/622d21/00000000000000003b9b204f/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3)
      format('woff2'),
    url(https://use.typekit.net/af/622d21/00000000000000003b9b204f/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3)
      format('woff'),
    url(https://use.typekit.net/af/622d21/00000000000000003b9b204f/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3)
      format('opentype');
  font-weight: 900;
  font-style: italic;
}
