@import 'styles/variables.scss';

.planimetry-selection {
  font-family: $PRIMARY_FONT;
  letter-spacing: 0.05em;

  .MuiDrawer-paper {
    margin: 0px;
    width: 100%;
    padding: 0;
  }

  .MuiPaper-root {
    z-index: 1400;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url('../assets/images/sfondo-riepilogo.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(30px) opacity(0.3);
    }
  }

  .promo {
    position: fixed;
    top: 185px;
    right: 0;
    z-index: 1;
    padding-right: 18px;

    @media screen and (max-width: 1024px) {
      top: 60px;
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      top: 55px;
    }
  }

  .fixed-spacer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 46px;

    &.only-one {
      position: relative;
    }
  }

  .content-container {
    width: 100%;
    padding: 16px;

    &.only-one {
      height: fill-available;

      @media screen and (max-width: $BREAKPOINT_MD) {
        height: auto;
      }
    }

    & .only-one {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 24px;
      padding: 18px;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.05em;
      background-color: #fff;
      color: $PRIMARY;

      @media screen and (max-width: 1024px) {
        padding: 8px;
        font-size: 14px;
        line-height: 21px;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        padding: 4px;
      }
    }

    .title-promo-container {
      display: grid;
      grid-template-columns: 1fr repeat(1, auto) 1fr;
      justify-items: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-height: 80px;
      padding: 11px 16px;
      background-color: #fff5ea;
      color: $PRIMARY;
      text-align: center;

      &-ce {
        grid-column-start: 2;

        &-apartment {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.05em;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 27px;
          }
          @media screen and (max-width: $BREAKPOINT_MD) {
            display: none;
          }
        }

        &-building {
          font-family: $PRIMARY_FONT;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.05em;

          @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 16px;
          }
          @media screen and (max-width: $BREAKPOINT_MD) {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }

      &-spacing {
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      @media screen and (max-width: 1024px) {
        max-height: 45px;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.05em;
      }
      @media screen and (max-width: $BREAKPOINT_MD) {
        max-height: 80px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.05em;
      }
    }

    .info-container {
      width: 100%;
      padding: 30px 25px 35px 25px;
      background-color: #f3f3f1;
      border-bottom: 2px solid $PRIMARY;

      @media screen and (max-width: 1024px) {
        padding: 20px 23px 35px 23px;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        padding: 16px 26px 26px;
      }

      .apartment-numbering {
        margin-bottom: 7px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: #333;

        @media screen and (max-width: $BREAKPOINT_MD) {
          display: none;
        }
      }

      .apartment-container {
        position: relative;
        &.more-apartments {
          margin-bottom: 37px;

          @media screen and (max-width: 1024px) {
            margin-bottom: 35px;
          }

          @media screen and (max-width: $BREAKPOINT_MD) {
            margin-bottom: 23px;
          }
        }

        .apartment-wrapper {
          align-content: flex-start;
          background: rgba(255, 255, 255, 0.7);
          border-top: 1px solid #989898;
          border-left: 1px solid #989898;
          border-bottom: 1px solid #989898;

          &-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-height: 53px;
            padding: 10px 25px;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.05em;
            color: $PRIMARY;
            border-bottom: 2px solid rgba(255, 255, 255, 0.63);

            &-se {
              font-weight: 700;
            }

            &-icon {
              width: 12px;
              height: 12px;
              margin-right: 13px;

              @media screen and (max-width: $BREAKPOINT_MD) {
                display: none;
              }
            }

            &-ribbon-ranking {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 3px;
              font-size: 15px;
              line-height: 22px;
              letter-spacing: 0.05em;
              background-color: rgba($SECONDARY, 0.3);
              color: $PRIMARY;
              border-radius: 16px;

              &-message {
                margin: 0 8px;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.05em;
              }

              // &-top {
              //   margin: 0 8px 0 4px;
              //   font-size: 12px;
              //   line-height: 18px;
              //   letter-spacing: 0.05em;
              // }

              // &-separator {
              //   @media screen and (max-width: $BREAKPOINT_MD) {
              //     display: none;
              //   }
              // }

              // &-choice {
              //   margin: 0 8px;

              //   @media screen and (max-width: $BREAKPOINT_MD) {
              //     display: none;
              //   }
              // }

              @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 21px;
              }

              @media screen and (max-width: $BREAKPOINT_MD) {
                margin: 0 6px 0 5px;
                font-size: 10px;
                line-height: 15px;
              }
            }

            @media screen and (max-width: 1024px) {
              max-height: 50px;
            }

            @media screen and (max-width: $BREAKPOINT_MD) {
              padding: 10px;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.05em;
            }
          }

          .apartment {
            padding: 10px;

            &-planimetry {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              padding: 10px;
              background-color: #fff;

              &-icon {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
              }

              &-image {
                width: 170px;
                background: rgba(255, 255, 255, 0.8);
                cursor: pointer;

                @media screen and (max-width: 1024px) {
                  width: 150px;
                  margin-top: 8px;
                }
                @media screen and (max-width: $BREAKPOINT_MD) {
                  width: 97px;
                }
              }
            }
          }

          .apartment-info {
            padding: 24px 22px;

            &-wrapper {
              &-title {
                margin-bottom: 6px;
                font-family: $PRIMARY_FONT;
                font-style: italic;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.05em;
                color: #87898d;

                @media screen and (max-width: $BREAKPOINT_MD) {
                  display: none;
                }
              }

              &-filters {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
                padding: 6px 0;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.05em;
                background: rgba(255, 255, 255, 0.8);
                color: rgba(91, 91, 91, 0.64);
                border-radius: 2px;

                &-modello,
                &-tipologia,
                &-dimensione {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  width: 100%;
                  padding: 0 13px;
                  margin-right: 4px;
                  border-right: 1px solid #dadada;

                  span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.05em;
                    color: #5b5b5b;
                    text-transform: uppercase;

                    @media screen and (max-width: $BREAKPOINT_MD) {
                      font-size: 12px;
                      line-height: 18px;
                    }
                  }

                  @media screen and (max-width: $BREAKPOINT_MD) {
                    padding: 0 6px;
                  }
                }

                &-dimensione {
                  border-right: none;
                }

                @media screen and (max-width: 1024px) {
                  margin-bottom: 8px;
                  padding: 5px 10px;
                }

                @media screen and (max-width: $BREAKPOINT_MD) {
                  margin-bottom: 7px;
                  padding: 0;
                  font-size: 10px;
                  line-height: 15px;
                }
              }
            }

            &-surfaces {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              &-separator {
                width: 10px;

                @media screen and (max-width: 1024px) {
                  width: 8px;
                }

                @media screen and (max-width: 1024px) {
                  height: 8px;
                }
              }

              &-total,
              &-lodge {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.05em;
                background-color: rgba(255, 255, 255, 0.5);
                color: #333;
                border: 1px solid $TERTIARY;
                border-radius: 2px;

                img {
                  padding: 25px;
                  border-right: 1px solid $TERTIARY;

                  @media screen and (max-width: 1024px) {
                    padding: 23px 15px;
                  }

                  @media screen and (max-width: $BREAKPOINT_MD) {
                    // padding: 13px;
                    display: none;
                    border-right: 0;
                  }
                }

                &-description {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  margin-left: 15px;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.05em;
                  color: #333;

                  & span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                  }

                  @media screen and (max-width: $BREAKPOINT_MD) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin: 0 15px;
                  }
                }

                @media screen and (max-width: 1024px) {
                  margin-left: 12px;
                }

                @media screen and (max-width: $BREAKPOINT_MD) {
                  margin-left: 0;
                  font-size: 12px;
                  line-height: 18px;
                }
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                flex-direction: column;
              }
            }

            &-separator {
              margin: 20px 0 15px;
              border-bottom: 2px solid rgba(255, 255, 255, 0.63);
            }

            &-warnings {
              display: flex;
              flex-direction: column;

              &-proposal {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                font-family: $PRIMARY_FONT;
                font-style: italic;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.05em;
                color: #f44748;

                & > svg {
                  width: 15px;
                  height: 15px;

                  @media screen and (max-width: 1024px) {
                    width: 14px;
                    height: 14px;
                  }

                  @media screen and (max-width: $BREAKPOINT_MD) {
                    width: 11px;
                    height: 11px;
                  }
                }
                & span {
                  margin-left: 5px;
                }

                @media screen and (max-width: $BREAKPOINT_MD) {
                  margin-bottom: 0;
                }
              }

              @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 18px;
              }
              @media screen and (max-width: $BREAKPOINT_MD) {
                font-size: 12px;
                line-height: 16px;
              }
            }

            @media screen and (max-width: 1024px) {
              padding: 11px 8px 0;
            }
          }
        }

        .apartment-promo {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          // align-items: center;
          padding: 0 35px;
          background: #fff;
          border: 1px solid $PRIMARY;

          &-title {
            padding: 10px 0;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.05em;
            text-align: start;
            color: $PRIMARY;

            @media screen and (max-width: $BREAKPOINT_MD) {
              font-size: 12px;
              line-height: 18px;
            }
          }

          &-price {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 0 37px;
            font-family: $PRIMARY_FONT;
            letter-spacing: 0.05em;
            text-align: center;
            color: #333;
            border-top: 2px solid #dadada;

            &-value {
              position: relative;
              font-weight: 700;
              font-size: 50px;
              line-height: 59px;
              letter-spacing: 0.05em;

              &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                border-top: 4px solid $PRIMARY;
                transform: matrix(1, 0.06, -0.06, 1, 0, 0);
              }

              @media screen and (max-width: $BREAKPOINT_MD) {
                font-size: 30px;
                line-height: 35px;
              }
            }

            &-description {
              font-style: italic;
              font-size: 18px;
              line-height: 21px;
              letter-spacing: 0.05em;

              @media screen and (max-width: 1024px) {
                font-size: 16px;
                line-height: 18px;
              }

              @media screen and (max-width: $BREAKPOINT_MD) {
                font-size: 14px;
                line-height: 16px;
              }
            }

            &-icon {
              margin-top: 20px;
              margin-bottom: 35px;

              @media screen and (max-width: 1024px) {
                margin-top: 20px;
                margin-bottom: 33px;
              }

              @media screen and (max-width: $BREAKPOINT_MD) {
                display: none;
              }
            }

            &-button {
              width: 80%;
              padding: 14px 0;
              background-color: $PRIMARY;
              border-radius: 4px;
              font-family: $PRIMARY_FONT;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.05em;
              color: #fff;

              @media screen and (max-width: 1024px) {
                width: 100%;
                padding: 13px 0;
              }

              @media screen and (max-width: $BREAKPOINT_MD) {
                padding: 12px 0;
                font-size: 12px;
                line-height: 14px;
              }
            }

            @media screen and (max-width: $BREAKPOINT_MD) {
              padding: 25px 0;
            }
          }

          &-timer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 12px 0;
            font-family: $PRIMARY_FONT;
            font-size: 12px;
            line-height: 14px;
            text-align: end;
            letter-spacing: 0.05em;
            color: #747474;

            &-icon {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }

            &-countdown {
              padding: 2px 4px;

              span {
                font-weight: 700;
              }
            }
          }

          @media screen and (max-width: $BREAKPOINT_MD) {
            padding: 0 15px;
          }
        }
      }
    }
  }

  .simple-rotate-button {
    position: fixed;
    bottom: 24px;
    left: 16px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .selected-planimetry {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-color: green;
    color: #fff;
    border-radius: 50%;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }
}
