@import 'styles/fontfaces.scss';
@import 'styles/variables.scss';

.home {
  font-family: $PRIMARY_FONT;

  &-content {
    &-rotate-button,
    &-exit-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 32px;
      font-family: $PRIMARY_FONT;
      font-size: 15px;
      // line-height: 18px;
      letter-spacing: 0.05em;
      color: #333;
      border: 1px solid $PRIMARY;
      border-radius: 40px;
      cursor: pointer;
      z-index: 1200;

      &.less-space {
        padding: 12px;
      }
    }

    &-rotate-button {
      right: 265px;
      padding: 12px 40px 12px 12px;
      font-weight: 600;
      background: linear-gradient(
        116.95deg,
        rgba(255, 255, 255, 0) 19.85%,
        rgba(255, 255, 255, 0.8) 81.98%
      );

      img {
        margin-right: 15px;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        display: none;
      }

      @media screen and (min-width: $BREAKPOINT_DESK) {
        position: relative;
        top: 0;
        right: 0;
        z-index: 0;
      }
    }

    &-exit-button {
      right: 40px;
      padding: 12px 12px 12px 18px;
      font-weight: 700;
      background-color: rgba(255, 255, 255, 0.42);
      text-transform: uppercase;

      span {
        margin-left: 10px;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        top: 24px;
        font-size: 12px;
        line-height: 14px;
      }

      @media screen and (min-width: $BREAKPOINT_DESK) {
        position: relative;
        top: 0;
        right: 0;
        margin-left: 20px;
        z-index: 0;
      }
    }
  }

  &-player-position {
    @media screen and (min-width: $BREAKPOINT_DESK) {
      position: relative;
      top: 90px;
      left: 516px;
      width: calc(100% - 516px);
      height: calc(100% - 90px - 170px);
    }
  }
}

.exit-popper {
  max-width: 339px;
  padding: 16px;
  font-family: $PRIMARY_FONT;
  letter-spacing: 0.05em;
  background-color: #fff;
  border-radius: 4px;
  z-index: 1300;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;

    &.rotate-180 {
      transform: rotate(180deg);
    }

    &-message {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;

      @media screen and (max-width: $BREAKPOINT_MD) {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &-warning {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
    }

    &-commands {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.0357143em;
      letter-spacing: 0.05em;

      &-undo {
        margin-right: 8px;
        padding: 10px;
        font-family: $PRIMARY_FONT;
        background-color: #fff;
        color: #333;
        border: 1px solid #e8e9eb;
        border-radius: 4px;
        text-transform: uppercase;

        @media screen and (max-width: $BREAKPOINT_MD) {
          margin-left: 3px;
        }
      }

      &-confirm {
        margin-left: 8px;
        padding: 10px 12px;
        font-family: $PRIMARY_FONT;
        background-color: $PRIMARY;
        color: #fff;
        border: 1px solid $PRIMARY;
        border-radius: 4px;
        text-transform: uppercase;

        @media screen and (max-width: $BREAKPOINT_MD) {
          margin-left: 3px;
        }
      }
    }
  }

  @media screen and (max-width: $BREAKPOINT_MD) {
    max-width: 298px;
  }
}
