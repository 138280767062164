@import 'styles/colors.scss';
@import 'styles/variables.scss';
@import 'virtual-keyboard';

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.login {
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/images/sfondo-generale.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: $PRIMARY_FONT;
  letter-spacing: 0.05em;

  &-wrapper {
    background-color: rgba(51, 51, 51, 0.31);
    backdrop-filter: blur(10px);

    &-rotate-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 32px;
      right: 40px;
      padding: 12px 40px 12px 12px;
      font-family: $PRIMARY_FONT;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      background: linear-gradient(
        116.95deg,
        rgba(255, 255, 255, 0) 19.85%,
        rgba(255, 255, 255, 0.8) 81.98%
      );
      color: #000;
      border: 1px solid $PRIMARY;
      border-radius: 40px;
      cursor: pointer;
      z-index: 1200;

      img {
        margin-right: 15px;
      }
    }

    &-exit-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 32px;
      left: 40px;
      padding: 12px 40px 12px 12px;
      font-family: $PRIMARY_FONT;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      background: linear-gradient(
        116.95deg,
        rgba(255, 255, 255, 0) 19.85%,
        rgba(255, 255, 255, 0.8) 81.98%
      );
      color: #000;
      border: 1px solid $PRIMARY;
      border-radius: 40px;
      cursor: pointer;
      z-index: 1200;

      img {
        margin-right: 15px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 100vh;

      &.rotate {
        transform: rotate(180deg);
      }

      & .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 118px;
        margin-top: 40px;
      }

      &-form-title {
        margin-bottom: 25px;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.05em;
        color: #fff;
        font-family: 'Lato', sans-serif;
        text-transform: uppercase;
      }

      &-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-input {
          display: flex;
          justify-content: center;
          width: 350px;
          max-height: 44px;
          background: rgba(255, 255, 255, 0.7);
          border-radius: 4px;

          & input {
            padding: 12.5px 14px;
            color: rgba(51, 51, 51, 0.8);
            font-family: $PRIMARY_FONT;
            border: none;

            &::placeholder {
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.05em;
              color: rgba(51, 51, 51, 0.4);
            }
          }

          & fieldset {
            border: none;
            outline: none;
          }
        }

        &-error {
          padding: 1rem 0;
          color: #f44336;
          font-size: 0.85rem;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: 0.05em;
        }

        &-button {
          width: 140px;
          height: 44px;
          background: $PRIMARY;
          color: #fff;
          font-family: $PRIMARY_FONT;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.05em;

          &:hover {
            background-color: $PRIMARY;
          }

          &.Mui-disabled {
            background-color: $SECONDARY;
            color: #fff;
          }
        }
      }

      & .keyboard-container {
        width: 80%;
        margin-bottom: 70px;

        @media screen and (max-width: 1024px) {
          visibility: hidden;
        }
      }
    }
  }
}

.right-half-login {
  display: flex;
  position: absolute;
  right: 0px;
  background: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  min-height: 600px;
  height: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
  }
}

.login-margin-bottom {
  margin-bottom: 15px;
}

.login-icon {
  @extend .login-margin-bottom;
  width: $LOGO_WIDTH_HUGE;
}

.login-form {
  display: contents;
}

.login-error {
  width: 60%;
  margin: 30px auto;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #f44336;
  font-family: 'Segoe', sans-serif;

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 75%;
  }
}

.login-input {
  width: 60%;
  margin-bottom: 40px;

  &.email {
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin-bottom: 26px;
    }
  }

  &.username {
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin-bottom: 26px;
    }
  }

  &.password {
    button {
      padding: 5px;
    }
    span > svg {
      height: 0.8em;
    }
  }

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 75%;

    input {
      font-size: 14px;
    }
  }
}

.login-button {
  min-width: 150px;
  height: 42px;
  margin-top: 35px;
  border-radius: 2px;
}

.login-logo-vendor {
  width: $LOGO_WIDTH_HUGE;
  position: absolute;
  bottom: 10px;
}

.login-title {
  color: black;
  letter-spacing: 3px;
  font-size: 28px;
}

.login-hint {
  color: $GREY_TEXT;
  font-size: 13px;
}

.left-half-login {
  width: 50%;
  position: absolute;
  left: 0px;
  object-fit: cover;
  height: 100%;
  // border-right: 1px solid black;
}

.login__wrapper .login-form__wrapper {
  label {
    // color: $loginFormTitleColor !important;
  }

  input[type='email'],
  input[type='password'] {
    // color: $loginFormTextColor !important;
    // background-color: $loginFormInputBackgroundColor !important;
    // border-color: $loginFormTextColor !important;
  }

  input::placeholder {
    // color: $loginFormTextColor !important;
  }

  .w-button {
    // background-color: $loginFormButtonBackgroundColor !important;
    // color: $loginFormButtonTextColor !important;

    &:hover {
      // background-color: $loginFormButtonBackgroundColor !important;
      // color: $loginFormButtonTextColor !important;
      // border-color: unset !important;
    }
  }
}

.home-configurator.login {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.round-button--rotate {
  display: block;
  position: fixed;
  top: 1rem;
  right: 7%;
  width: 50px;
  height: 50px;
  margin: 0;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #08353b;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 10;
}

.login__wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  > * {
    flex: 0 0 100%;
  }
  .primary-logo {
    width: 200px;
    height: 120px;
    margin: 1rem auto;
    display: block;
    filter: brightness(0) invert(1);
  }
  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.25;
    margin: 1rem auto 3rem;
  }
  .login-form__wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 320px;
    justify-content: center;
    align-items: center;
    height: 270px;
    margin: 0 auto 2rem auto;
    padding: 2rem 2rem 2rem 2rem;
    background-color: rgba(9, 54, 59, 0.9);
    box-shadow: 0 0 30px 10px rgba(128, 95, 95, 0.4);
    box-sizing: border-box;

    .login-form-title {
      margin: 15px auto 25px;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 700;
      padding: 0 0 0.7rem 0;
      display: block;
    }

    input[type='email'] {
      height: 35px;
      color: #fff;
      background-color: transparent;
      border-color: #fff;
      border: 2px solid #fff;
    }

    input::placeholder {
      color: #fff;
      opacity: 1;
    }

    .login-form-error {
      font-size: 0.85rem;
      color: #fff;
      font-weight: 700;
      padding: 1rem 0 0;
      line-height: 15px;
    }

    .login-form-button {
      width: 150px;
      margin: 1rem 1rem 0;
      background-color: #fff;
      color: #09363b;
      font-size: 16px;
      font-weight: 400;
      border-radius: 0;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
  hr {
    flex: 0 0 50%;
  }
  label {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0 0 0.7rem 0;
    display: block;
  }
  form#email-form > div:not(.input-wrap) {
    font-size: 0.85rem;
    // color: $white !important;
    font-weight: 700;
    padding: 1rem 0 0 0;
    margin: 0 !important;
    display: block;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    margin: 0.7rem 0 0 0;
    padding: 0.2rem 0.5rem;
    border: 0;
    &:focus {
      outline: none;
    }
    &.w-input {
      // height: 35px;
      // font-size: $paragraph-size;
      // color: $text-color;
      // border: 1px solid lighten($black, 80%);
    }
    &.w-button {
      // background: $black;
      // color: $white;
      // margin: $sm-space 1rem 0 1rem;
      padding: 0.5rem 0;
      transition: opacity 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
