@import 'styles/variables.scss';

.promo-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //   width: 207px;
  // height: 57px;
  margin-left: auto;
  padding: 0 12px;
  background-color: red;
  color: #ffffff;
  font-family: $PRIMARY_FONT;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  border-left: 2px solid #ff0000;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  & > * {
    margin: 0;
    font-weight: 400;
  }

  &-title {
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (min-width: $BREAKPOINT_MD) {
      font-size: 16px;
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }
  &-description {
    margin-top: -5px;
    font-size: 10px;
    font-weight: 300;
    font-style: italic;
    line-height: 15px;
    letter-spacing: 0.05em;

    @media screen and (min-width: $BREAKPOINT_MD) {
      font-size: 16px;
      padding-bottom: 5px;
    }
  }
  &-timer {
    display: flex;
    align-items: center;

    &-date {
    }

    img {
      margin-left: 0;
    }

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;

      @media screen and (min-width: $BREAKPOINT_MD) {
        width: 24px;
        height: 24px;
        margin-right: 2px;
      }
    }
    &-countdown {
      margin-bottom: 2px;
      padding: 1px 4px;
      border: 1px solid #f44748;
      border-radius: 4px;

      @media screen and (min-width: $BREAKPOINT_MD) {
        font-size: 16px;
      }

      span {
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }

  //   @media screen and (min-width: 1025px) {
  //     display: none;
  //   }
}

.only-timer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0;
  font-family: $PRIMARY_FONT;
  font-size: 12px;
  line-height: 14px;
  text-align: end;
  letter-spacing: 0.05em;
  color: #747474;

  &-icon {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  &-countdown {
    padding: 2px 4px;

    span {
      font-weight: 700;
    }
  }
}
