.rotate-phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
}

@media screen and (orientation: landscape) {
  .rotate-phone {
    display: none;
  }
}

.rotate-phone img {
  width: 250px;
  height: 272px;
}

.rotate-phone p {
  width: 60%;
  margin: 34px auto 24px;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .rotate-phone img {
    width: 185px;
    height: 201px;
  }

  .rotate-phone p {
    margin: 47px auto 25px;
    font-size: 20px;
    line-height: 24px;
  }
}

.main-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
