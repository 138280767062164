@import 'styles/variables.scss';

$drawerWidth: 400px;
$drawerWidthMD: 300px;
$drawerWidthSM: 250px;

.hidden {
  visibility: hidden;
}

.filter-container {
  position: relative;
  font-family: $PRIMARY_FONT;
  font-size: 16px;
  letter-spacing: 0.05em;
  z-index: 1201 !important;

  &-header {
    .logo-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      & img {
        width: 200px;

        @media screen and (max-width: 1024px) {
          width: 150px;
        }
        @media screen and (max-width: $BREAKPOINT_MD) {
          width: 100px;
        }
      }
    }

    .filters-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 41px;

      &-title {
        font-family: $PRIMARY_FONT;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff;
        margin: 40px 0 20px;

        & .filters-header-highlight {
          color: $PRIMARY;
        }

        @media screen and (max-width: 1024px) {
          margin: 25px 0 16px;
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.05em;
        }
        @media screen and (max-width: $BREAKPOINT_MD) {
          margin: 10px 0 0;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.05em;
        }
      }

      &-body {
        margin: 8px 0 30px;
        padding: 0;
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-align: center;

        @media screen and (max-width: 1024px) {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.05em;
        }

        @media screen and (max-width: $BREAKPOINT_MD) {
          margin: 8px 0;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.05em;
        }
      }

      &-icon {
        @media screen and (max-width: $BREAKPOINT_MD) {
          display: none;
        }
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 34px;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        margin-bottom: 8px;
      }
    }
  }

  &-content {
    border-top: 1px solid #f9f6ee;
    border-right: 1px solid #f9f6ee;
    border-left: 1px solid #f9f6ee;
  }

  &-min-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    &-value {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.05em;
      color: #fff;
    }
  }

  &-reset-button {
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 16px;
    font-family: $DEFAULT_FONT;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: inherit;
    background-color: rgba(241, 241, 242, 0.8);
    color: #333333;
    border: 1px solid $PRIMARY;
    border-radius: 4px;
    float: right;

    &:hover {
      background-color: rgba(224, 224, 224, 0.8);
    }

    @media screen and (max-width: $BREAKPOINT_MD) {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }

  .MuiPaper-root {
    background: rgba(30, 32, 31, 0.55);
    backdrop-filter: blur(20px);
  }

  // h2 {
  //   font-weight: 400;
  //   margin: 20px 0px 20px 0px;
  // }
  .MuiPaper-root {
    margin: 0px;
    max-width: $drawerWidth;
    padding: 30px;

    @media screen and (max-width: 1024px) {
      max-width: $drawerWidthMD;
      padding: 32px 24px;
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      max-width: $drawerWidthSM;
      padding: 24px 15px;
    }
  }

  .shiftFabRight {
    left: calc(#{$drawerWidth} + 25px);

    @media screen and (max-width: 1024px) {
      left: calc(#{$drawerWidthMD} - 25px);
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      left: calc(#{$drawerWidthSM} - 25px);
    }
  }

  .booking-alert {
    margin-top: 32px;
  }

  .legend-container {
    margin-top: 25px;

    @media screen and (max-width: 1024px) {
      margin-top: 15px;
    }

    .legend {
      display: flex;
      align-items: center;
      padding: 14px 0;

      &.separator {
        border-bottom: 1px solid #fff;
      }

      @media screen and (max-width: $BREAKPOINT_MD) {
        padding: 12px 0;
      }

      &-icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        // border-radius: 50%;
        &.disponibili {
          background-color: rgba(34, 146, 227, 0.43);
          border: 1px solid #2292e3;
        }
        &.opzionati {
          background-color: rgba(220, 189, 25, 0.43);
          border: 1px solid #dcbd19;
        }
      }
      &-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // width: 60%;
        color: #fff;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.05em;

        span:last-child {
          font-style: italic;
        }

        @media screen and (max-width: 1024px) {
          // width: 80%;
          font-size: 16px;
          line-height: 19px;
        }
        @media screen and (max-width: $BREAKPOINT_MD) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .no-available-apartments {
    margin-top: 60px;
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.back-to-login-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 35px;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  .vendor-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: none;
    min-width: 140px;
    min-height: 44px;
    border: 1px solid $PRIMARY;
    border-radius: 4px;

    &-icon {
      padding: 11px 15px;
      border-right: 1px solid $PRIMARY;
    }

    &-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 12px;
      font-family: $PRIMARY_FONT;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #333;

      & .highlight {
        font-weight: 700;
      }
    }

    @media screen and (max-width: 1024px) {
      min-width: 122px;
      min-height: 38px;
      font-size: 11px;
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      min-width: 105px;
      min-height: 22px;
      font-size: 9px;
    }
  }
  .client-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: none;
    min-width: 140px;
    min-height: 44px;
    border: 1px solid $PRIMARY;
    border-radius: 4px;

    &-icon {
      padding: 11px 15px;
      border-right: 1px solid $PRIMARY;
    }

    &-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 12px;
      font-family: $PRIMARY_FONT;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #333;

      & .highlight {
        font-weight: 700;
      }
    }

    @media screen and (max-width: 1024px) {
      min-width: 122px;
      min-height: 38px;
      font-size: 11px;
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      min-width: 105px;
      min-height: 22px;
      font-size: 9px;
    }
  }
}

.button-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 32px;
  left: 24px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #fff;
  color: $PRIMARY;
  transform: rotate(180deg);
  z-index: 1202;
  cursor: pointer;
  // box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.2), 0px 8px 17px 0px rgba(49, 60, 73, 0.5467);
  transition: left 180ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.open {
    left: calc(#{$drawerWidth} - 22px);
    transform: rotate(0deg);

    @media screen and (max-width: 1024px) {
      left: calc(#{$drawerWidthMD} - 22px);
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      left: calc(#{$drawerWidthSM} - 22px);
    }
  }

  .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }

  @media screen and (max-width: $BREAKPOINT_MD) {
    top: 24px;
  }
}

.fake-menu {
  position: relative;
  width: 40px;
  height: 100vh;
  background: rgba(30, 32, 31, 0.55);
  backdrop-filter: blur(20px);
  z-index: 1200;
}

.filters-menu {
  width: 516px;
  height: 100vh;
  background-image: url('../assets/images/sfondo-menu.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(51, 51, 51, 0.71);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 55px 88px;
    backdrop-filter: blur(20px);

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo-half-top {
        cursor: pointer;
      }

      .back-to-login-buttons {
        margin-top: 45px;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 44px 0;
      border-top: 1px solid $PRIMARY;
      border-bottom: 1px solid $PRIMARY;

      &-title {
        font-family: $PRIMARY_FONT;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;

        &-highlight {
          color: $PRIMARY;
        }
      }

      &-body {
        margin: 8px 0 30px;
        padding: 0;
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }

      &-min-price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        text-align: center;

        &-value {
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.05em;
          color: #fff;
        }
      }

      &-icon {
        margin-bottom: 57px;
      }

      &-selection {
        border-top: 1px solid #f9f6ee;
        border-right: 1px solid #f9f6ee;
        border-left: 1px solid #f9f6ee;
      }

      &-reset-button {
        margin-top: 24px;
        padding: 10px 20px;
        font-family: $PRIMARY_FONT;
        font-style: italic;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-transform: inherit;
        background-color: $PRIMARY;
        color: #fff;
        border: 1px solid $PRIMARY;
        border-radius: 4px;
        float: right;

        &:hover {
          background-color: $PRIMARY;
        }
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-welcome {
        margin-bottom: 25px;
        font-family: $PRIMARY_FONT;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        color: #fff;
      }
    }
  }
}

.view-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25px;
  left: 0;
  z-index: 1200;

  &-separator {
    width: 25px;
    height: 25px;
  }

  &.shiftFabRight {
    left: calc(#{$drawerWidth} + 25px);

    @media screen and (max-width: 1024px) {
      left: calc(#{$drawerWidthMD} + 25px);
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      left: calc(#{$drawerWidthSM} + 25px);
    }
    @media screen and (min-width: $BREAKPOINT_DESK) {
      left: 0;
    }
  }

  &.shiftFabLeft {
    left: 65px;
  }

  @media screen and (max-width: $BREAKPOINT_MD) {
    flex-direction: column;
  }
}
