@import 'styles/variables.scss';

.logo-promo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 23px;
  background-color: #f0f1f1;
  font-family: $PRIMARY_FONT;

  & .logo {
    width: 150px;
    margin-left: 20px;
    @media screen and (max-width: 1024px) {
      width: 108px;
      margin-left: 8px;
    }
    @media screen and (max-width: $BREAKPOINT_MD) {
      width: 90px;
      margin-left: 1px;
    }
  }

  .back-to-login-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    .vendor-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 50%;
      padding: 0;
      font-size: 14px;
      text-transform: none;
      min-width: 140px;
      min-height: 44px;
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid $PRIMARY;
      border-radius: 4px;

      &-icon {
        padding: 11px 15px;
        border-right: 1px solid $PRIMARY;
      }

      &-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 12px;
        font-family: $PRIMARY_FONT;
        font-size: 14px;
        line-height: 16px;
        color: #333;

        & .highlight {
          font-weight: 700;
        }
      }

      @media screen and (max-width: 1024px) {
        min-width: 122px;
        min-height: 38px;
        font-size: 11px;
      }
      @media screen and (max-width: $BREAKPOINT_MD) {
        min-width: 105px;
        min-height: 22px;
        font-size: 9px;
      }
    }
    .client-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 50%;
      padding: 0;
      font-size: 14px;
      text-transform: none;
      min-width: 140px;
      min-height: 44px;
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid $PRIMARY;
      border-radius: 4px;

      &-icon {
        padding: 11px 15px;
        border-right: 1px solid $PRIMARY;
      }

      &-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 12px;
        font-family: $PRIMARY_FONT;
        font-size: 14px;
        line-height: 16px;
        color: #333;

        & .highlight {
          font-weight: 700;
        }
      }

      @media screen and (max-width: 1024px) {
        min-width: 122px;
        min-height: 38px;
        font-size: 11px;
      }
      @media screen and (max-width: $BREAKPOINT_MD) {
        min-width: 105px;
        min-height: 22px;
        font-size: 9px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 18px 24px;
  }
  @media screen and (max-width: $BREAKPOINT_MD) {
    padding: 8px 21px;
  }
}
