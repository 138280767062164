.keyboard-container {
  display: flex;
  justify-content: center;
  //position: absolute;
  left: 0;
  bottom: 2vh;
  width: 100%;
}

.keyboard-container > .react-simple-keyboard {
  max-width: 70%;
  font-size: 1.5vh;
}

.hg-button {
  height: 4.5vh;
  border-radius: 0.4vh;
  color: #000;
  &:not(:last-child) {
    margin-right: 0.5vh !important;
  }
}

.hg-functionBtn {
  justify-content: center;
  align-items: center;
}

.hg-button-bksp,
.hg-button-enter,
.hg-button-shift:last-child {
  justify-content: center;
}

.hg-button-space {
  flex-grow: 10 !important;
}

.modal.modal__zoom-plan.full-page {
  .react-simple-keyboard {
    min-width: 600px !important;
  }
}
